import styles from './/AboutMe.css'

const AboutMe = () => {
    return (
        <div className="aboutmesection" id="aboutmesection">
            <h2>About me</h2>
            <p>
                I am a reliable, conscientious, hard-working software developer. I enjoy using my technical skills to solve real-world problems.
            </p>

            <p>
                The focus of my career has been front end development, including React.js, React Native and Microsoft's Power Platform.
            </p>

            <p>
                Prior to joining the tech world I had a long and rewarding career in clinical trials, monitoring trials for quality and compliance, leading project teams and training site staff and junior team members. My work experience also includes line management and project team management.
            </p>

            <h2>Technical Skills</h2>
            <ul>
                <li>Power Apps (Canvas and Model-driven) | Power Automate | Power BI</li>
                <li>JavaScript | React Native | Firebase | React | HTML | CSS</li>
                <li>Ruby | Ruby on Rails</li>
                <li>Quality Control | Unit Testing | E2E Testing | Jest | Rspec Rails | Cypress | React Testing Library</li>
                <li>Figma | SASS | Bootstrap | Tailwind</li>
                <li>Azure DevOps | Jira | Confluence | Trello </li>
                <li>Stripe | Jira | PostgreSQL | MySQL | Heroku | Netlify</li>
                <li>Responsive design | Accessibility | Agile methodology</li>
            </ul>

            <h2>Certifications</h2>
            <ul>
                <li>Microsoft Power Platform Fundamentals (PL-900), Jan-2022</li>
                <li>In progress - Microsoft Power Platform App Maker Associate (PL-100)</li>
            </ul>

            <h2>Professional skills</h2>
            <ul>
                <li>Client management</li>
                <li>Line management, team leadership, training and mentoring</li>
                <li>Excellent written and verbal communication skills</li>
                <li>Strong attention to detail</li>
                <li>Approachable, able to build relationships with both technical and non-technical team members</li>
                <li>Growth mindset, willing to take on feedback, strive for constant improvement</li>
                <li>Focus on simple, maintainable solutions</li>
            </ul>
            
        </div>

        
    )
}

export default AboutMe;