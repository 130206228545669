import styles from './/Contact.css'
import LinkedInLogo from '../../assets/linkedin_icon.png';
import GitHubLogo from '../../assets/Github_Logo.jpg';
import EmailIcon from '../../assets/Email_icon.png'

const Contact = () => {
    return (
        <div className='contactcontainer'>
            <div className="contactsection" id="contactsection" >
                <h2>Contact</h2>

                <div className='contactcard'>
                    <img src={LinkedInLogo} width='50' alt='...'/>
                    <h5>LinkedIn:</h5>
                    <a href="www.linkedin.com/in/katherine-rock-3b795770" >www.linkedin.com/in/katherine-rock-3b795770</a>
                </div>

                <div className='contactcard'>
                    <img src={GitHubLogo} width='50' alt='...'/>
                    <h5>GitHub:</h5>
                    <a href="https://github.com/katherine-rock" >https://github.com/katherine-rock</a>
                </div>

                <div className='contactcard'>
                    <img src={EmailIcon} width='50' alt='...'/>
                    <h5>Email:</h5>
                    <a href="mailto:katherine_rock@hotmail.com" >katherine_rock@hotmail.com</a>
                </div>

            </div>

        </div>
    )
}

export default Contact;